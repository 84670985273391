import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import users from "../../../api/users";
import {
    Anchor,
    Badge,
    LoadingOverlay,
    SimpleGrid,
    Button,
    Grid,
    Col,
    Spoiler,
    Breadcrumbs,
    Text,
    Divider,
    ActionIcon,
    Tooltip,
} from "@mantine/core";
import {User} from "../../../entity/User";
import {useNotifications} from "@mantine/notifications";
import storage from "../../../auth/storage";
import {useModals} from "@mantine/modals";
import {CheckIcon} from "@heroicons/react/solid";
import {FaUser} from "react-icons/fa";
import {
    FiUserCheck,
    FiUserX, GiTicket,
    IoIosCopy,
    IoMdOpen,
    MdOutlineCancel,
} from "react-icons/all";
import {useClipboard, useWindowScroll} from "@mantine/hooks";
import {RiFolderSharedLine} from "react-icons/ri";
import formation from "../../../api/formation";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import CardCollection from "../../../components/workshop/CardCollection";
import HasRoles from "../../../components/Security/hasRoles";
import {
    ROLE_XMAN,
    ROLE_BEMAN_MORE,
    ROLE_DXMAN_MORE,
} from "../../../constants/roles";
import {FaCopy} from "react-icons/fa";
import Footer from "../../Footer";
import {CSVLink} from "react-csv";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import {Modal} from "@mantine/core";
import transactions from "../../../api/transactions";
import creditHistory from "../../../api/creditHistory";
import { 
    format_d_MMMM_YYYY,
    format_dddd_d_MM_YYYY,
    format_dddd_d_MMMM_YYYY
} from "../../../utility/dateUtility";

const ItemWorkshopWrapped = ({
    id,
    openedPaypal,
    setopenedPaypal,
    dataWorkshop,
    setDataWorkshop,
    totalPrice,
    setTotalPrice,
    Completed,
    setCompleted,
    setRequestLoading,
    requestLoading,
    request,
    loading,
} : any) => {
    const user: any = storage.getUser();
    const modals = useModals();
    const [isGuest, setIsGuest] = useState();
    const [isCopilotApp, setIsCopilotApp] = useState();
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [isJD, setIsJD] = useState<boolean>(false);
    const [canSeeGuest, setCanSeeGuest] = useState(false);
    const [canSeeExtract, setCanSeeExtract] = useState(false);
    const [isFormer, setIsFormer] = useState(Boolean);
    const [godFatherEmails, setGodFatherEmails] = useState<any[]>([]);
    const [isInWaitingZone, setIsInWaitingZone] = useState(false);
    const [canBeGuest, setCanBeGuest] = useState(true);
    const [isCopilot, setIsCopilot] = useState();
    const [csv, setCsv] = useState<any[]>([]);
    const notifications = useNotifications();
    const {request: requestEditUser} = useApi(users.edit);
    const {data: dataUser, request: requestGetUser} = useApi(users.me);
    const clipboard = useClipboard({timeout: 800});
    const clipboard2 = useClipboard({timeout: 800});
    const navigation = useNavigate();
    const [opened, setOpened] = useState(false);
    const [opened2, setOpened2] = useState(false);
    const [scroll, scrollTo] = useWindowScroll();
    // console.log('dataworkshop', dataWorkshop)
    const {request: requestUser} = useApi(users.me);

    useEffect(() => {
        window.scrollTo(0, 0);
        requestGetUser();
    }, []);
    const {request: requestTransaction} = useApi(transactions.getAllByUser);
    const {request: requestTransactionSend} = useApi(transactions.CreateTransaction);

    useEffect(() => {
        // @ts-ignore
        setIsGuest(!!dataWorkshop?.guests.find((guest) => guest.id === user?.id));
        // @ts-ignore
        setIsInWaitingZone(!!dataWorkshop?.waitingZone.find((guest) => guest.id === user?.id));
        // @ts-ignore
        setIsCopilot(!!dataWorkshop?.coPilotWanted.find((coPilota) => coPilota.id === user?.id));
        // @ts-ignore
        setIsCopilotApp(!!dataWorkshop?.copilotApprouved.find((coPilota) => coPilota.id === user?.id));

        setIsFormer(dataWorkshop?.former?.id === user?.id);


        setIsJD(dataWorkshop?.title.toLowerCase().includes("Découverte".toLowerCase()));


        setCsv(
            dataWorkshop?.guests.map((guest: any) => {
                return {
                    prenom: guest.firstName,
                    nom: guest.lastName,
                    email: guest.email,
                    telephone: "'" + guest.phoneNumber,
                    parrain_prenom: guest.godFather
                        ? guest.godFather?.firstName
                        : "pas de parrain",
                    parrain_nom: guest.godFather
                        ? guest.godFather?.lastName
                        : "pas de parrain",
                    telephone_parrain:
                        "'" + guest.godFather?.phoneNumber || "pas de parrain",
                };
            })
        );
        requestTransaction(user.id).then((res: any) => {
            res.data.find((item: any) => {
                if (item.idWorkshop == id) {
                    console.log(item.idWorkshop, id)
                    setIsPaid(true);
                }
            })
        });

    }, [dataWorkshop]);

    useEffect(() => {
        if (user.id == dataWorkshop?.former?.id) {
            setCanSeeGuest(true)
            setCanSeeExtract(true)
        }
        ;
        if (user.role[0] == "ROLE_XMAN") {
            setCanSeeGuest(true);
            setCanSeeExtract(true);
        }
        if (user.role[0] == "ROLE_MAN") {
            setCanSeeGuest(true);
            setCanSeeExtract(true);
        }
        ;
        if (user.role[0] == "ROLE_DXMAN") {
            setCanSeeGuest(true);
            setCanSeeExtract(true);
        }
        ;
        if (user.role[0] == "ROLE_DEVMAN") {
            setCanSeeGuest(true);
            setCanSeeExtract(true);
        }
        ;
        if (isCopilotApp) {
            setCanSeeGuest(true);
            setCanSeeExtract(true);
        }
        ;
        if (isGuest) setCanSeeGuest(true);
    }, [dataWorkshop, isCopilotApp, isGuest]);

    const handlePaid = () => {
        const date = new Date();
        requestUser(user.id).then((res:any) => {
            if (typeof id === "string") {
                requestTransactionSend({
                    user: res?.data?.id ?? null,
                    email: res?.data?.email ?? '',
                    lastName: res?.data?.lastName ?? '',
                    firstName: res?.data?.firstName ?? '',
                    society: res?.data?.society ?? null,
                    city: res?.data?.city ?? null,
                    address: res?.data?.address ?? null,
                    postalCode: res?.data?.postalCode ?? null,
                    createAt: (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()),
                    paypalIdTransaction: "STRIPE PAYMENT",
                    paypalAccountStatus: "COMPLETED",
                    //@ts-ignore
                    paymentPaypalDate: (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()),
                    paymentMethod: "PAYMENT_BY_CREDIT",
                    total: dataWorkshop.price.toString(),
                    quantity: 1,
                    itemName: "Paiement Journée Découverte parrain " + id,
                    workshop: parseInt(id),
                    formation: undefined,
                    idWorkshop: parseInt(id),
                    IdFormation: undefined
                })
                    .then((res:any) => {
                        // @ts-ignore
                        setIsPaid(true)
                        notifications.showNotification({
                            title: "Nice !",
                            message: "Vous venez de finaliser l'inscription avec vos crédits !",
                            color: "green",
                        });
                    })
            }
        })
    }


    const handleCopilot = async () => {
        setRequestLoading(true);
        if (!isCopilot) {
            const result = await workshop.registerCopilot(Number(id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: " L'inscription n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            } else {
                notifications.showNotification({
                    title: "Nice !",
                    message:
                        "Une notification a été envoyée au pilote ,Il validera votre co-animation si la place est toujours disponible et vous recevrez une notification. ! 😀",
                    color: "green",
                });
                setRequestLoading(false);
            }
        }
        if (isCopilot) {
            const result = await workshop.unRegisterCopilot(Number(id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: "L'annulation n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            } else {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Vous êtes maintenant plus inscrit ! 😀",
                    color: "green",
                });
                setRequestLoading(false);
            }
        }
        request(id).then((r:any) => {
            setDataWorkshop(r.data);
        });
    };


    useEffect(() => {
        if (dataWorkshop && dataUser && user.role[0] == "ROLE_GUEST" && dataWorkshop.title == "3 Jours de la Réussite") {
            if (dataUser.isDemarrageEnded) {
                setCanBeGuest(true)
            } else {
                console.log("PEUX PAS VOIR")
                setCanBeGuest(false)
            }
        }
    }, [dataUser, dataWorkshop])

    useEffect(() => {
        console.log("CAN BE GUEST", canBeGuest)
    }, [canBeGuest])

    useEffect(() => {
        if (dataWorkshop?.guests?.length > 0) {
            const godFather: any[] = [];
            dataWorkshop.guests.forEach((guest: any) => {
                if (guest.godFather) {
                    godFather.push(
                        dataWorkshop.guests.filter((g: any) => g.id === guest.godFather.id)
                    );
                }
            });
            setGodFatherEmails(godFather);
        }
    }, [dataWorkshop?.guests]);

    const handleGuest = async () => {
        if (isGuest) {
            setRequestLoading(true);
            const result = await workshop.unRegisterGuest(Number(id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: "L'annulation n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            } else {
                const date1 = new Date(dataWorkshop?.startDate);
                const date2 = new Date();
                // @ts-ignore
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (diffDays <= 7) {
                    notifications.showNotification({
                        title: "Nice !",
                        message: "Vous n'êtes maintenant plus inscrit ! 😀",
                        color: "green",
                    });
                    request(id).then((r:any) => {
                        setDataWorkshop(r.data);
                        requestGetUser();
                    });
                    setRequestLoading(false);
                } else {
                    const newCredit = dataUser.credit + dataWorkshop.price;
                    requestEditUser({credit: newCredit}, dataUser.id).then(() => {
                        creditHistory.save({
                            user: dataUser.id,
                            amount: dataWorkshop.price,
                            type: "crédit",
                            description: "Remboursement des frais d'inscription à un atelier"
                        })
                    });
                    notifications.showNotification({
                        title: "Nice !",
                        message: "Vous n'êtes maintenant plus inscrit ! 😀",
                        color: "green",
                    });
                    request(id).then((r:any) => {
                        setDataWorkshop(r.data);
                        requestGetUser();
                    });

                    setRequestLoading(false);
                }
            }
        }
        if (!isGuest) {
            setTotalPrice(dataWorkshop?.price);
            if (dataUser?.credit > 0) {
                if (dataUser?.credit >= dataWorkshop?.price) {
                    const result = await workshop.registerGuest(Number(id));
                    if (!result.ok) {
                        notifications.showNotification({
                            title: "Une erreur est survenue.",
                            message: `L'inscription n'a pas pu aboutir ! 🤥`,
                            color: "red",
                        });
                    } else {
                        const newCredit = dataUser.credit - dataWorkshop.price;
                        console.log("credit", newCredit);
                        requestEditUser({credit: newCredit}, dataUser.id).then(() => {
                            creditHistory.save({
                                user: dataUser.id,
                                amount: - dataWorkshop.price,
                                type: "débit",
                                description: "Inscription à un atelier"
                            })
                            notifications.showNotification({
                                title: "Nice !",
                                message: "Vous êtes maintenant inscrit ! 😀",
                                color: "green",
                            });
                        });
                    }
                    request(id).then((r:any) => {
                        setDataWorkshop(r.data);
                        requestGetUser();
                    });
                } else {
                    setTotalPrice(dataWorkshop?.price - dataUser?.credit);
                    setopenedPaypal(true);
                }
            } else {
                setTotalPrice(dataWorkshop?.price);
                setopenedPaypal(true);
            }
        }
    };

    useEffect(() => {
        if (Completed === true) {
            setopenedPaypal(false);
            const register = async () => {
                setRequestLoading(true);
                const result = await workshop.registerGuest(Number(id));
                if (!result.ok) {
                    dataUser.credit = dataUser.credit + totalPrice;
                    requestEditUser({credit: dataUser.credit}, dataUser.id).then(() => {
                        creditHistory.save({
                            user: dataUser.id,
                            amount: totalPrice,
                            type: "crédit",
                            description: "Echec d'une inscription à un atelier"
                        })
                    });
                    notifications.showNotification({
                        title: "Une erreur est survenue.",
                        message: `L'inscription n'a pas pu aboutir ! 🤥`,
                        color: "red",
                    });
                } else {
                    if (dataUser.credit > 0) {
                        dataUser.credit =
                            dataUser.credit - (dataWorkshop.price - totalPrice);
                        requestEditUser({credit: dataUser.credit}, dataUser.id).then(() => {
                            creditHistory.save({
                                user: dataUser.id,
                                amount: - (dataWorkshop.price - totalPrice),
                                type: "débit",
                                description: "Inscription à un atelier"
                            })
                        });
                    }
                    notifications.showNotification({
                        title: "Nice !",
                        message: "Vous êtes maintenant inscrit ! 😀",
                        color: "green",
                    });
                }
            };
            register().then(() => {
                request(id).then((r:any) => {
                    setDataWorkshop(r.data);
                });
                requestGetUser();
            });
            setCompleted(false);
            setRequestLoading(false);
        }
    }, [Completed]);

    const handleGuestWaitingZone = async () => {
        setRequestLoading(true);
        if (isInWaitingZone) {
            const result = await workshop.unRegisterInWaitingZone(Number(id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: "L'annulation n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            } else {
                notifications.showNotification({
                    title: "Nice !",
                    message:
                        "Vous êtes maintenant plus inscrit dans la file d'attente ! 😀",
                    color: "green",
                });
                setRequestLoading(false);
            }
        }
        if (!isInWaitingZone) {
            const result = await workshop.registerInWaitingZone(Number(id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: "L'inscription n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            } else {
                notifications.showNotification({
                    title: "Nice !",
                    message: "Vous êtes maintenant inscrit dans la file d'attente ! 😀",
                    color: "green",
                });
                setRequestLoading(false);
            }
        }
        request(id).then((r:any) => {
            setDataWorkshop(r.data);
        });
    };

    useEffect(() => {
        // @ts-ignore
        request(id).then((r:any) => {
            if (
                !r.data.destined.find(
                    (item: any) =>
                        item == user.role || user.pilot || user.role == "ROLE_XMAN"
                )
            ) {
                navigation("/");
            } else {
                setDataWorkshop(r.data);
                console.log("test", r?.data?.site);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const items = [
        {title: "Accueil", href: "/auth/home", ref: null},
        {title: "Ateliers", href: "/auth/workshop", ref: null},
        {
            title:
                dataWorkshop &&
                dataWorkshop?.title +
                " le " +
                format_d_MMMM_YYYY(dataWorkshop?.startDate),
            href: `/auth/workshop/detail/${id}`,
            ref: dataWorkshop?.site?.name,
        },
    ].map((item, index) => (
        // @ts-ignore
        <Anchor
            component={Link}
            to={item.href}
            key={index}
            style={{color: "#333", fontSize: 14}}
        >
            {item.title}
        </Anchor>
    ));
    // console.log(new Date(dataWorkshop?.startDate).toLocaleDateString('fr-FR', {
    //     year: 'numeric',
    //     month: 'short',
    //     day: 'numeric',}));
    const confirm = () => {
        const id = modals.openModal({
            title: "Copilote",
            transition: "fade",
            transitionDuration: 600,
            transitionTimingFunction: "ease",
            children: (
                <>
                    <Text size="sm" className="my-5">
                        Vous êtes sur le point de proposer votre profil comme copilote pour
                        l'atelier {dataWorkshop?.title} du{" "}
                        {format_dddd_d_MM_YYYY(dataWorkshop?.startDate)}{" "}
                        à {dataWorkshop?.site?.name} organisé par{" "}
                        {dataWorkshop?.former.lastName} {dataWorkshop?.former.firstName}.
                    </Text>
                    <SimpleGrid cols={2}>
                        <Button
                            color="dark"
                            fullWidth
                            onClick={() => modals.closeModal(id)}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="default"
                            fullWidth
                            onClick={() => {
                                handleCopilot();
                                modals.closeModal(id);
                            }}
                        >
                            Confirmer
                        </Button>
                    </SimpleGrid>
                </>
            ),
        });
    };

    const handleRemove = async () => {
        setRequestLoading(true);
        const result = await workshop.remove(Number(id));
        if (!result.ok) {
            notifications.showNotification({
                title: "Une erreur est survenue.",
                message: "L'Atelier n'a pas pu être supprimé ! 🤥",
                color: "red",
            });
            setRequestLoading(false);
        } else {
            notifications.showNotification({
                title: "Nice !",
                message: "Atelier supprimé ! 😀",
                color: "green",
            });
            setRequestLoading(false);
            navigation("/auth/workshop", {replace: true});
        }
    };

    const confirmCancel = () => {
        const id = modals.openModal({
            title: "Copilote",
            transition: "fade",
            transitionDuration: 600,
            transitionTimingFunction: "ease",
            children: (
                <>
                    <Text size="sm" className="my-5">
                        Vous êtes sur le point d'annuler votre profil comme copilote pour
                        l'atelier {dataWorkshop?.title} du{" "}
                        {format_dddd_d_MM_YYYY(dataWorkshop?.startDate)}{" "}
                        à {dataWorkshop?.site?.name} organisé par{" "}
                        {dataWorkshop?.former.lastName} {dataWorkshop?.former.firstName}.
                    </Text>
                    <SimpleGrid cols={2}>
                        <Button
                            color="dark"
                            fullWidth
                            onClick={() => modals.closeModal(id)}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant="default"
                            fullWidth
                            onClick={() => {
                                handleCopilot();
                                modals.closeModal(id);
                            }}
                        >
                            Confirmer
                        </Button>
                    </SimpleGrid>
                </>
            ),
        });
    };

    console.log("isFORMER", isFormer);

    // @ts-ignore
    return (
        <>
            <div className="absolute left-2 xl:left-60 top-5">
                <Link to="/auth/workshop">
                    <Button
                        className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
                        color="lime"
                        size="xs"
                        variant="filled"
                    >
                        Retour à la liste
                    </Button>
                </Link>
            </div>
            <div className="w-screen md:w-auto px-8 py-2 md:pb-4">
                <Breadcrumbs
                    className="my-5 text-gray-800"
                    separator="/"
                    styles={{separator: {color: "#333"}}}
                >
                    {items}
                </Breadcrumbs>
            </div>

            <div className="bg-white shadow-md md:flex">
                <div
                    className={`py-5 px-6 ${
                        !isCopilot && !isFormer ? "md:w-8/12" : "w-full mr-10"
                    }`}
                >
                    <h2 className="font-bold mb-1 text-thirteen text-center md:text-left text-gray-600">
                        {dataWorkshop?.thematic}
                    </h2>
                    <h2 className="text-4xl mb-6 font-extralight text-center md:text-left text-gray-900">
                        {dataWorkshop?.title}
                    </h2>
                    {window.innerWidth < 768 ? (
                        <>
                            <p className="text-thirteen text-center text-gray-600 uppercase">
                                {format_d_MMMM_YYYY(dataWorkshop?.startDate)}
                            </p>
                            <p className="text-thirteen text-center text-gray-600">
                                {dataWorkshop?.site?.address} {dataWorkshop?.site?.postalCode}{" "}
                                {dataWorkshop?.site?.city}
                            </p>{" "}
                        </>
                    ) : null}

                    <div className="md:flex mt-6 items-center md:mt-0 md:justify-between">
                        <div className="flex">
                            <img
                                className="w-12 h-12 rounded-md"
                                src={profilePicture}
                                alt="profile"
                            />
                            <div className="ml-4">
                <span className="text-ten leading-3 text-gray-600 font-bold block">
                  PILOTE
                </span>
                                <span className="text-thirteen text-gray-600">
                  {dataWorkshop?.former.firstName}{" "}
                                    <span className="uppercase text-thirteen">
                    {dataWorkshop?.former.lastName}
                  </span>
                </span>
                            </div>
                            {dataWorkshop?.copilotApprouved?.map((copilot: any) => (
                                <div className="ml-4">
                  <span className="text-ten leading-3 text-gray-600 font-bold block">
                    COPILOTE
                  </span>
                                    <span className="text-thirteen text-gray-600">
                    {copilot.firstName}{" "}
                                        <span className="uppercase text-thirteen">
                      {copilot.lastName}
                    </span>
                  </span>
                                </div>
                            ))}
                        </div>
                        {window.innerWidth > 768 ? (
                            <span className="text-thirteen float-right text-gray-600 text-right uppercase">
                {format_d_MMMM_YYYY(dataWorkshop?.startDate)}
              </span>
                        ) : null}
                    </div>

                    <div className="mt-3 md:flex md:justify-between">
                        <HasRoles role={ROLE_BEMAN_MORE} checkUser>
                            {dataWorkshop?.wantCopilot && (
                                <Anchor
                                    className="text-gray-600"
                                    onClick={!isCopilot ? confirm : confirmCancel}
                                >
                                    {!isCopilot ? (
                                        <h2 className="inline-block text-thirteen hover:underline">
                                            <FaUser className="w-6 h-6 inline-block mb-1 ml-3 mr-6"/>{" "}
                                            Se proposer comme copilote{" "}
                                        </h2>
                                    ) : (
                                        <span className="inline-block text-thirteen hover:underline">
                      {" "}
                                            <MdOutlineCancel
                                                color="red"
                                                className="inline-block mb-1 mr-3"
                                            />
                      Annuler ma demande de copilote{" "}
                    </span>
                                    )}
                                </Anchor>
                            )}
                        </HasRoles>
                        {window.innerWidth > 768 ? (
                            <span className="text-thirteen text-gray-600 float-right">
                {dataWorkshop?.site?.address} {dataWorkshop?.site?.postalCode}{" "}
                                {dataWorkshop?.site?.city}
              </span>
                        ) : null}
                    </div>
                </div>
                {(isFormer || isCopilot || isCopilotApp) && (
                    <div className={`m-auto md:w-2/12 `}>
            <span className="  bg-pink-500 font-bold text-thirteen p-2 mx-3 text-white rounded-md">
              Tarif : {` ${dataWorkshop?.price} €`}
            </span>
                    </div>
                )}

                <div
                    className={`m-auto text-center ${
                        !isCopilot && !isCopilotApp && !isFormer ? "md:w-2/12" : "hidden"
                    }`}
                >
                    <div className="text-center w-screen md:w-auto">
                        {!isGuest &&
                            dataWorkshop?.totalGuests >= dataWorkshop?.maxGuests &&
                            !isInWaitingZone &&
                            !isCopilot &&
                            !isCopilotApp &&
                            !isFormer && (
                                <>
                                    <h2 className="text-yellow-500 font-bold text-thirteen text-center">
                                        Complet
                                    </h2>
                                    <button
                                        onClick={() => console.log()}
                                        className="w-full text-white bg-yellow-500 text-thirteen p-2 mt-3 font-bold rounded"
                                    >
                                        Voir les prochaines dates
                                    </button>

                                    <button
                                        onClick={() => handleGuestWaitingZone()}
                                        className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                                    >
                                        {!isInWaitingZone
                                            ? "S'inscrire sur la liste d'attente"
                                            : "Se retirer de la file d'attente"}
                                    </button>
                                    <div className="text-thirteen text-gray-600 text-left">
                                        Déjà{" "}
                                        <span className="font-bold  text-thirteen text-gray-600">
                      {dataWorkshop?.waitingZone?.length} personne(s)
                    </span>
                                    </div>
                                </>
                            )}
                        {isInWaitingZone && (
                            <>
                                <button
                                    onClick={() => handleGuestWaitingZone()}
                                    className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                                >
                                    {!isInWaitingZone
                                        ? "S'inscrire sur la liste d'attente"
                                        : "Se retirer de la file d'attente"}
                                </button>
                            </>
                        )}
                        {!isCopilot && !isCopilotApp && isGuest && !isPaid && isJD && (
                            dataUser?.credit < 5 ? (
                                    <>
                                        <button
                                            className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                                            onClick={() => setopenedPaypal(true)}>
                                            <span
                                                className="ml-3">Finalisez votre inscription de 5 €</span>
                                        </button>
                                    </>) :
                                (
                                    <button
                                        className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                                        onClick={() => handlePaid()}>
                                        <span
                                            className="ml-3">Finalisez votre inscription de 5 €</span>
                                    </button>
                                )
                        )}
                        {!dataWorkshop?.isUniqueWaiting &&
                            !isGuest && canBeGuest &&
                            dataWorkshop?.totalGuests < dataWorkshop?.maxGuests &&
                            !isCopilot &&
                            !isCopilotApp &&
                            !isFormer && (
                                <button
                                    onClick={() => handleGuest()}
                                    className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                                >
                                    Je m'inscris {dataWorkshop?.price} €
                                </button>
                            )}
                        {(isFormer || isCopilot || isCopilotApp) && <span>{dataWorkshop?.price} €</span>}

                        {dataWorkshop?.isUniqueWaiting == true && isInWaitingZone && canBeGuest && !isGuest &&
                            dataWorkshop?.totalGuests < dataWorkshop?.maxGuests &&
                            !isCopilot
                            && !isCopilotApp &&
                            !isFormer && (
                                <button
                                    onClick={() => handleGuest()}
                                    className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                                >
                                    Je m'inscris {dataWorkshop?.price} €
                                </button>
                            )}
                        {(isFormer || isCopilot  || isCopilotApp ) && <span>{dataWorkshop?.price} €</span>}
                        {isGuest && (
                            <>
                                <FiUserCheck size="30" className="text-green-600 mx-auto"/>
                                <h2 className="text-green-600 font-bold text-thirteen text-center mb-3">
                                    {isGuest && !isPaid && isJD ? ("Déjà préinscrit") : ("Déjà inscrit")}
                                </h2>
                                {dataWorkshop && dataWorkshop.startDate && new Date(dataWorkshop.startDate) > new Date() && (
                                    <Button
                                        onClick={() => handleGuest()}
                                        size="sm"
                                        color="gray"
                                        variant="filled"
                                    >
                  <span className="inline-block mx-auto">
                    <FiUserX
                        size="18"
                        className="inline-block text-thirteen mx-auto mr-3"
                    />{" "}
                      J'annule
                  </span>
                                    </Button>)}
                            </>
                        )}
                    </div>
                    {!isCopilot &&
                        !isCopilotApp &&
                        !isFormer &&
                        !isGuest &&
                        dataWorkshop?.totalGuests < dataWorkshop?.maxGuests && (
                            <>
                                <div
                                    className={`flex ${
                                        window.innerWidth < 768
                                            ? "justify-around md:justify-between mx-4 pb-2"
                                            : "flex-col"
                                    } mt-1`}
                                >
                                    <div className="text-thirteen text-gray-600 inline-block">
                                        INSCRITS:{" "}
                                        <span className="font-bold text-gray-600 text-thirteen">
                      {dataWorkshop?.totalGuests} / {dataWorkshop?.maxGuests}
                    </span>
                                    </div>
                                    <div className="text-thirteen text-gray-600 inline-block mr-1">
                                        Vos crédits :{" "}
                                        <span className="font-bold text-thirteen">
                      {dataUser?.credit} €
                    </span>
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </div>

            <div className="w-screen md:w-auto px-6 py-2 md:p-12 md:pt-5">
                <div className="md:flex md:justify-between mt-8">
                    <div className="md:w-8/12">
                        <div className="my-10">
                            <h2 className="text-s21 uppercase mb-4">Pré-requis / Objectif</h2>
                            <div className="bg-white shadow p-3 rounded">
                                <p
                                    className="break-all text-thirteen"
                                    dangerouslySetInnerHTML={{
                                        __html: dataWorkshop?.prerequisite,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="my-10">
                            <h2 className="text-s21 uppercase mb-4">
                                Description / Mots du formateur
                            </h2>
                            <div className="bg-white shadow p-3 rounded">
                                <p
                                    className="break-all text-thirteen"
                                    dangerouslySetInnerHTML={{
                                        __html: dataWorkshop?.formerWords,
                                    }}
                                />
                            </div>
                        </div>

                        {dataUser?.roles && dataUser?.roles[0] != "ROLE_GUEST" && (
                            <div className="md:flex md:justify-between">
                                <div className="md:w-6/12">
                                    <h2 className="text-s21 uppercase">Public visé </h2>
                                    <div className="bg-white shadow p-3 mt-2 rounded">
                                        {!dataWorkshop?.destined ||
                                            (dataWorkshop?.destined.length == 0 && (
                                                <p className="text-thirteen">Aucun public visé</p>
                                            ))}
                                        {dataWorkshop?.destined &&
                                            dataWorkshop?.destined.map(
                                                (name: string, index: Number) => (
                                                    <Badge
                                                        className="mx-1 md:rounded text-ten md:bg-gray-500 bg-white text-black md:text-white"
                                                        variant="filled"
                                                    >
                                                        {name == "ROLE_GUEST" ? "INVITÉ" : name.slice(5)}
                                                    </Badge>
                                                )
                                            )}
                                    </div>
                                </div>

                                <div className="md:w-5/12 mt-8 md:mt-0">
                                    <h2 className="text-s21 uppercase">
                                        Habilitations Nécessaires
                                    </h2>
                                    <div className="bg-white shadow p-3 mt-2 rounded">
                                        {!dataWorkshop?.habilitation ||
                                            (dataWorkshop?.habilitation.length == 0 && (
                                                <p className="text-thirteen">
                                                    Aucune habilitation nécessaire
                                                </p>
                                            ))}
                                        {dataWorkshop?.habilitation &&
                                            dataWorkshop?.habilitation.map(
                                                (name: string, index: Number) => (
                                                    <Badge
                                                        className="mx-1 md:rounded text-thirteen md:bg-gray-500 bg-white text-black md:text-white"
                                                        variant="filled"
                                                    >
                                                        {name}
                                                    </Badge>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-evenly mx-auto mt-10 items-center space-x-2">
                            <HasRoles
                                role={ROLE_DXMAN_MORE}
                                checkIsAuthor
                                checkUser
                                datas={dataWorkshop}
                            >
                                <Link to={"/auth/workshop/edit/" + id}>
                                    <Button
                                        className="text-thirteen hover:text-white hover:bg-yellow-600 w-auto bg-gray-100 text-gray-400 shadow"
                                        color="lime"
                                        size="xs"
                                        variant="filled"
                                    >
                                        Modifier
                                    </Button>
                                </Link>
                            </HasRoles>
                            <HasRoles
                                role={ROLE_DXMAN_MORE}
                                checkIsAuthor
                                checkUser
                                datas={dataWorkshop}
                            >
                                <Button
                                    onClick={() => handleRemove()}
                                    className="text-thirteen hover:text-white hover:bg-red-600 w-auto bg-gray-100 text-gray-400 shadow"
                                    color="lime"
                                    size="xs"
                                    variant="filled"
                                >
                                    Supprimer
                                </Button>
                            </HasRoles>
                        </div>

                        {window.innerWidth > 768 ? <div className="my-10"></div> : null}
                    </div>

                    <div className="md:w-3/12">
                        <div>
                            <h2 className="text-s21 uppercase md:text-right mt-8 md:mt-0 mb-2">
                                Date et Horaires
                            </h2>
                            <div className="bg-white shadow p-3 mt-5 rounded">
                                <h2 className="text-thirteen uppercase text-gray-600 md:text-right">
                                    Date et Horaires
                                </h2>
                                <h4 className="text-thirteen text-gray-600 font-bold md:text-right">
                                    {dataWorkshop?.startDate == dataWorkshop?.endDate && format_dddd_d_MMMM_YYYY(dataWorkshop?.startDate)}
                                    {dataWorkshop?.startDate != dataWorkshop?.endDate && format_dddd_d_MMMM_YYYY(dataWorkshop?.startDate) + " - " + format_dddd_d_MMMM_YYYY(dataWorkshop?.endDate)}
                                </h4>

                                {dataWorkshop?.workshopDates?.map(
                                    (date: any, index: Number) => {
                                        const startDate = new Date(date.dateAt);
                                        startDate.setHours(startDate.getHours() - 1);
                                        const endAt = new Date(date.endAt);
                                        endAt.setHours(endAt.getHours() - 1);
                                        return (
                                            <span className="text-thirteen block text-gray-600 md:text-right">
                        {startDate.toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}{" "}
                                                -{" "}
                                                {endAt.toLocaleTimeString("fr-FR", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                      </span>
                                        );
                                    }
                                )}
                                {dataWorkshop?.workshopDates?.length == 0 && (
                                    <span className="text-thirteen block text-gray-600 md:text-right">
                    9h-18h
                  </span>
                                )}
                            </div>
                        </div>

                        {!dataWorkshop?.isVisio && (
                            <div>
                                <h2 className="text-s21 md:text-right mt-8 mb-2">LIEU</h2>
                                <div className="bg-white shadow p-3 mt-5 rounded-b-none rounded-t text-right">
                                    <h4 className="text-thirteen">
                                        {dataWorkshop?.site?.address}
                                    </h4>
                                    <h4 className="text-thirteen">
                                        {dataWorkshop?.site?.postalCode} {dataWorkshop?.site?.city}
                                    </h4>
                                </div>
                                {dataWorkshop?.site?.gmap && (
                                    <a
                                        href={dataWorkshop?.site?.gmap}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            radius="xs"
                                            className="bg-gray-600 px-10 rounded-none rounded-b"
                                            size="md"
                                            variant="filled"
                                            fullWidth
                                        >
                      <span className="text-thirteen">
                        Voir la carte interactive
                      </span>
                                        </Button>
                                    </a>
                                )}
                            </div>
                        )}
                        {canSeeGuest && (
                            <>
                                <div className="bg-white shadow p-3 mt-8 rounded text-center">
                                    {dataWorkshop?.totalGuests >= dataWorkshop?.maxGuests ? (
                                        <h2 className="text-yellow-500 font-bold text-thirteen text-center">
                                            Complet
                                        </h2>
                                    ) : (
                                        <>
                                            <h2 className="text-pink-600 text-thirteen font-bold ">
                                                {dataWorkshop?.totalGuests +  dataWorkshop?.copilotApprouved.length} inscrits
                                            </h2>
                                            <h2 className="text-pink-600 text-thirteen ">
                                                sur {dataWorkshop?.maxGuests} places
                                            </h2>
                                        </>
                                    )}

                                    <Spoiler
                                        maxHeight={60}
                                        showLabel="Voir plus d'inscrits"
                                        hideLabel="Moins"
                                        transitionDuration={100}
                                    >
                                        <div className="pb-5">
                                            {dataWorkshop?.guests.map(
                                                (guest: User, index: Number) => (
                                                    <div key={"" + index} className="mt-2 mb-1">
                                                        <p className="text-thirteen">
                                                            {guest.lastName} {guest.firstName}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                            {dataWorkshop?.copilotApprouved &&
                                                dataWorkshop?.copilotApprouved.map(
                                                    (copilot: any, index: Number) => (
                                                        <div key={"" + index} className="mt-2 mb-1">
                                                            <p className="text-thirteen">
                                                                {copilot.lastName} {copilot.firstName}
                                                            </p>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </Spoiler>
                                </div>
                                {dataWorkshop?.totalGuests >= dataWorkshop?.maxGuests && (
                                    <div className="bg-white shadow p-3 mt-8 rounded text-center">
                                        <h2 className="text-pink-600 text-thirteen font-bold ">
                                            {dataWorkshop?.waitingZone?.length} inscrits
                                        </h2>
                                        <Spoiler
                                            maxHeight={60}
                                            showLabel="Voir plus d'inscrits"
                                            hideLabel="Moins"
                                            transitionDuration={100}
                                        >
                                            <div className="pb-5">
                                                {dataWorkshop?.waitingZone.map(
                                                    (guest: User, index: Number) => (
                                                        <div key={"" + index} className="mt-2 mb-1">
                                                            <p className="text-thirteen">
                                                                {guest.lastName} {guest.firstName}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Spoiler>
                                    </div>
                                )}
                            </>
                        )}
                        {!isGuest && dataWorkshop?.totalGuests >= dataWorkshop?.maxGuests && !isInWaitingZone && (
                            <>
                                <Button
                                    onClick={() => console.log()}
                                    className="px-10 mt-3"
                                    size="md"
                                    color="orange"
                                    variant="filled"
                                    fullWidth
                                >
                  <span className="text-thirteen">
                    Voir les prochaines dates
                  </span>
                                </Button>
                                {!isCopilot && !isCopilotApp && !isFormer && (
                                    <Button
                                        onClick={() => handleGuestWaitingZone()}
                                        className="px-10 mt-5"
                                        size="md"
                                        color="gray"
                                        variant="filled"
                                        fullWidth
                                    >
                  <span className="text-thirteen">
                    {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                  </span>
                                    </Button>
                                )}
                            </>
                        )}
                        {isInWaitingZone && (
                            <>
                                <button
                                    onClick={() => handleGuestWaitingZone()}
                                    className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                                >
                                    {!isInWaitingZone
                                        ? "S'inscrire sur la liste d'attente"
                                        : "Se retirer de la file d'attente"}
                                </button>
                            </>
                        )}

                        {window.innerWidth < 768 ? (
                            <div className="text-center mt-8">
                                {!dataWorkshop?.isUniqueWaiting && !isGuest && canBeGuest &&
                                    dataWorkshop?.totalGuests < dataWorkshop?.maxGuests && (
                                        <button
                                            onClick={() => handleGuest()}
                                            className="bg-pink-600 font-bold w-full py-5 text-thirteen text-white rounded-md"
                                        >
                                            Je m'inscris {dataWorkshop?.price} €
                                        </button>
                                    )}
                                {dataWorkshop?.isUniqueWaiting && isInWaitingZone && !isGuest && canBeGuest &&
                                    dataWorkshop?.totalGuests < dataWorkshop?.maxGuests && (
                                        <button
                                            onClick={() => handleGuest()}
                                            className="bg-pink-600 font-bold w-full py-5 text-thirteen text-white rounded-md"
                                        >
                                            Je m'inscris {dataWorkshop?.price} €
                                        </button>
                                    )}
                                {isGuest && (
                                    <>
                                        <FiUserCheck size="30" className="text-green-600 mx-auto"/>
                                        <h2 className="text-green-600 font-bold text-thirteen text-center mb-3">
                                            Déjà inscrit
                                        </h2>
                                        {dataWorkshop && dataWorkshop.startDate && new Date(dataWorkshop.startDate) > new Date() && (
                                            <Button
                                                onClick={() => handleGuest()}
                                                size="xl"
                                                color="gray"
                                                variant="filled"
                                            >
                      <span className="inline-block mx-auto">
                        <FiUserX
                            size="18"
                            className="inline-block mx-auto mr-3"
                        />{" "}
                          J'annule
                      </span>
                                            </Button>)}
                                    </>
                                )}
                                <div className="flex justify-between mt-1">
                                    <div className="text-thirteen text-gray-600 inline-block">
                                        INSCRITS{" "}
                                        <span className="font-bold text-thirteen  text-gray-600">
                      {dataWorkshop?.totalGuests} / {dataWorkshop?.maxGuests}
                    </span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {canSeeExtract && (
                            <>
                                <button
                                    onClick={() => {
                                        clipboard2.copy(
                                            dataWorkshop?.guests
                                                .map((guest: any) => guest.email)
                                                .join("; ")
                                        );
                                        setOpened((o) => !o);
                                    }}
                                    className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
                                >
                                    <p
                                        className={`flex text-gray-400 bg-white p-2 font-medium float-right ${
                                            clipboard2.copied
                                                ? "text-green-400 text-thirteen"
                                                : "text-gray-400 text-thirteen"
                                        }`}
                                    >
                                        {clipboard2.copied ? "Copié" : "Copier les emails INSCRITS"}{" "}
                                        <FaCopy className="ml-1 mt-1"/>
                                    </p>
                                </button>

                                <button
                                    onClick={() => {
                                        clipboard.copy(
                                            dataWorkshop?.guests
                                                .map((guest: any) =>
                                                    guest.godFather
                                                        ? guest.godFather.email ?? "aucun"
                                                        : "aucun"
                                                )
                                                .join("; ")
                                        );
                                        setOpened2((o) => !o);
                                    }}
                                    className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
                                >
                                    <p
                                        className={`flex text-gray-400 font-medium bg-white p-2 text-thirteen float-right ${
                                            clipboard.copied ? "text-green-400 " : "text-gray-400"
                                        }`}
                                    >
                                        {clipboard.copied ? "Copié" : "Copier les emails PARRAINS"}{" "}
                                        <FaCopy className="ml-1 mt-1"/>
                                    </p>
                                </button>

                                <button className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full">
                                    {!loading && csv && csv.length > 0 && (
                                        <p className="flex text-gray-400 bg-white p-2 font-medium float-right">
                                            {
                                                //@ts-ignore
                                                <CSVLink
                                                    separator=";"
                                                    filename={
                                                        "liste de " + dataWorkshop?.thematic + ".csv"
                                                    }
                                                    data={csv}
                                                >
                                                    Extraire la liste
                                                </CSVLink>
                                            }
                                            <RiFolderSharedLine className="ml-1 mt-1"/>
                                        </p>
                                    )}
                                </button>
                            </>
                        )}

                        {dataWorkshop?.isVisio && (
                            <div className="mt-1">
                                <h2 className="text-s21 uppercase md:text-right mt-8 md:mt-0 mb-2">
                                    VISIOCONFÉRENCE
                                </h2>
                                <div className="bg-white shadow p-3 mt-3 rounded-b-none rounded-t">
                                    <h4 className="text-thirteen">
                                        Participer à la réunion Zoom
                                    </h4>
                                    <Divider className="my-2"/>
                                    <Tooltip
                                        className="float-right justify-center pb-2"
                                        wrapLines
                                        withArrow
                                        transition="fade"
                                        transitionDuration={200}
                                        label="Copier le lien"
                                    >
                                        <Tooltip
                                            delay={500}
                                            label="Copié !"
                                            position="right"
                                            opened={opened}
                                        >
                                            <ActionIcon
                                                className="float-right justify-center pb-2"
                                                component="button"
                                                onClick={() => {
                                                    clipboard.copy(dataWorkshop?.videoConferenceLink);
                                                    setOpened((o) => !o);
                                                }}
                                            >
                                                <IoIosCopy size={15}/>
                                            </ActionIcon>
                                        </Tooltip>
                                    </Tooltip>
                                    <Tooltip
                                        className="float-right justify-center pb-2"
                                        wrapLines
                                        withArrow
                                        transition="fade"
                                        transitionDuration={200}
                                        label="Ouvrir le lien"
                                    >
                                        <ActionIcon
                                            className="float-right justify-center pb-2"
                                            component="button"
                                            onClick={() =>
                                                window.open(dataWorkshop?.videoConferenceLink, "_blank")
                                            }
                                        >
                                            <IoMdOpen size={15}/>
                                        </ActionIcon>
                                    </Tooltip>
                                    <h4 className="text-thirteen ">Lien</h4>
                                    <Divider className="my-2"/>
                                    <Tooltip
                                        className="float-right text-thirteen justify-center pb-2"
                                        wrapLines
                                        withArrow
                                        transition="fade"
                                        transitionDuration={200}
                                        label="Copier le mot de passe"
                                    >
                                        <Tooltip
                                            delay={500}
                                            position="right"
                                            label="Copié !"
                                            opened={opened2}
                                        >
                                            <ActionIcon
                                                component="button"
                                                className="float-right justify-center pb-2"
                                                onClick={() => {
                                                    clipboard.copy(dataWorkshop?.visioPassword);
                                                    setOpened2((o) => !o);
                                                }}
                                            >
                                                <IoIosCopy size={15}/>
                                            </ActionIcon>
                                        </Tooltip>
                                    </Tooltip>
                                    <h4 className="text-thirteen">Mot de passe :</h4>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Footer/>
            </div>
            {/* Bouton je m'inscris qui suis quand on scroll */}
            {window.innerWidth > 768
                ? null
                : scroll.y > 450 && (
                <div className="fixed top-0 bg-white shadow-xl pb-3">
                    <div className="pb-2 pt-3 px-6 md:w-8/12">
                        <h2 className="text-2xl mb-1 font-extralight text-gray-900 uppercase">
                            {dataWorkshop?.title}
                        </h2>
                        <div className="flex justify-between">
                            <p className="text-thirteen text-gray-600 uppercase">
                                {format_d_MMMM_YYYY(dataWorkshop?.startDate)}
                            </p>
                            <p className="text-thirteen text-center text-gray-600 uppercase">
                                {dataWorkshop?.place}
                            </p>
                        </div>
                    </div>
                    <div className="m-auto text-center md:w-2/12">
                        <div className="text-center w-screen md:w-auto px-3">
                            {!isGuest &&
                                dataWorkshop?.totalGuests >= dataWorkshop?.maxGuests && (
                                    <>
                                        <h2 className="text-yellow-500 font-bold text-thirteen text-center">
                                            Complet
                                        </h2>
                                        <button
                                            onClick={() => console.log()}
                                            className="w-full text-white bg-yellow-500 text-thirteen p-2 mt-1 font-bold rounded"
                                        >
                                            Voir les prochaines dates
                                        </button>
                                        <button
                                            onClick={() => handleGuestWaitingZone()}
                                            className="mb-1 w-full text-white bg-gray-500 text-thirteen p-2 mt-2 font-bold rounded"
                                        >
                                            {!isInWaitingZone
                                                ? "S'inscrire sur la liste d'attente"
                                                : "Se retirer de la file d'attente"}
                                        </button>
                                        <span className="text-thirteen text-gray-600 float-right font-bold text-right">
                          {dataWorkshop?.price} €
                        </span>
                                        <div className="text-thirteen text-gray-600 text-left">
                                            Déjà{" "}
                                            <span className="font-bold text-gray-600">
                            {dataWorkshop?.waitingZone?.length} personne(s)
                          </span>
                                        </div>
                                    </>
                                )}

                            {!isGuest && canBeGuest &&
                                dataWorkshop?.totalGuests < dataWorkshop?.maxGuests && (
                                    <button
                                        onClick={() => handleGuest()}
                                        className="bg-pink-600 object-contain text-white rounded-md flex justify-center float-right"
                                    >
                                        <p className="my-2 px-3 text-thirteen font-bold">
                                            Je m'inscris {dataWorkshop?.price} €{" "}
                                        </p>
                                    </button>
                                )}
                            {isGuest && (
                                <>
                                    <FiUserCheck
                                        size="25"
                                        className="text-green-600 mx-auto"
                                    />
                                    <h2 className="text-green-600 font-bold text-thirteen text-center mb-3">
                                        Déjà inscrit
                                    </h2>
                                    {dataWorkshop && dataWorkshop.startDate && new Date(dataWorkshop.startDate) > new Date() && (
                                        <Button
                                            onClick={() => handleGuest()}
                                            className="px-10"
                                            size="md"
                                            color="gray"
                                            variant="filled"
                                        >
                        <span className="inline-block mx-auto">
                          <FiUserX
                              size="16"
                              className="inline-block text-thirteen mx-auto mr-3 "
                          />{" "}
                            J'annule
                        </span>
                                        </Button>)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ItemWorkshopWrapped;
