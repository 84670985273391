import request from "./request";

const endpoint = "/credit_histories";
const securedEndpoint = "/expert-accountant";


const collection = () => {
    return request.get(endpoint);
};
const expertAccountantCollection = (selectedYear:any, page:number) => {
    return request.get(`${securedEndpoint}/credit-history/${selectedYear}/${page}`);
};
const detail = (userId:any, selectedYear:any, page:number) => {
    return request.get(`${securedEndpoint}/credit-detail/${userId}/${selectedYear}/${page}`);
};
const save = (data:any) => {
    return request.post(endpoint, {
        type: data.type,
        amount: data.amount,
        description: data.description,
        user: "/api/users/" + data.user,
        date: data.date ?? new Date().toISOString(),
    });
};

const exported = {
    save,
    detail,
	collection,
	expertAccountantCollection
}


export default exported;