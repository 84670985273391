import React, { useEffect, useState } from 'react';
import {
  AppShell,
  Navbar,
  Burger,
  Divider,
  Menu,
  Button,
  TextInput,
  Select,
} from '@mantine/core';
import {Link, Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';
import useAuth from '../../auth/useAuth';
import logo_fm from '../../assets/images/logo_FM.png';
import hand from '../../assets/images/hello-hand.png';
import useApi from '../../hooks/useApi';
import users from '../../api/users';
import { Anchor, MantineProvider } from '@mantine/core';
import goals from '../../api/goals';
import finishedGoals from '../../api/finishedGoals';
import {
  FaUserAlt,
  FaBell,
  AiOutlineMenu,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
  AiOutlineTeam,
  GrCertificate,
  BsFillCalendarDateFill,
  FaRegNewspaper,
  BsFillQuestionSquareFill,
  AiOutlineMail,
  MdOutlineClose,
  GiHamburgerMenu,
  BsSearch,
  MdLogout,
} from 'react-icons/all';

import profilePicture from '../../assets/images/profile-picture-test.png';
import connect from '../../assets/images/triangleConnect.png';
import logoAtelier from '../../assets/images/SVG/atelier.svg';
import logoFormation from '../../assets/images/SVG/formation.svg';
import logoEvent from '../../assets/images/SVG/event.svg';
import logoActu from '../../assets/images/SVG/actu.svg';
import logoFAQ from '../../assets/images/SVG/faq.svg';
import logoContact from '../../assets/images/SVG/contact.svg';
import logoReunion from '../../assets/images/SVG/reunion.svg';
import picto_FM from '../../assets/images/picto_FM.png';
import { alignProperty } from '@mui/material/styles/cssUtils';
import 'dayjs/locale/fr';
import { URL2 } from '../../constants/URL';
import NotificationCard from '../../components/notifications/NotificationCard';
import storage from '../../auth/storage';
import { ROLE_XMAN } from '../../constants/roles';
import {useNotifications} from "@mantine/notifications";

const HomeConnected = () => {
  let navigate = useNavigate();
  const { logout } = useAuth();
  const [isAuth, setIsAuth] = useState(useAuth().isAuthenticated());
  console.log(isAuth);
  const { isAdmin }: any = storage.getUser();
  const [menuLinks] = useState([
    {
      id: 0,
      title: 'Ateliers',
      title2: 'Ateliers',
      link: '/auth/workshop',
      icon: <img className={"text-white"} src={logoAtelier} alt="Atelier" />,
    },
    {
      id: 1,
      title: "Réunions d'équipe",
      title2: 'Réunion',
      link: '/auth/TeamMeeting',
      icon: <img className={"text-white"} src={logoReunion} alt="Reunion equipe" />,
    },
    {
      id: 2,
      title: 'Formations',
      title2: 'Formations',
      link: '/auth/formation',
      icon: <img className={"text-white"} src={logoFormation} alt="Formation" />,
    },
    {
      id: 3,
      title: 'évenements',
      title2: 'évenements',
      link: '/auth/event',
      icon: <img className={"text-white"} src={logoEvent} alt="Evènement" />,
    },
    {
      id: 4,
      title: 'actualités',
      title2: 'actualités',
      link: '/auth/news',
      icon: <img className={"text-white"} src={logoActu} alt="Actualités" />,
    },
    {
      id: 5,
      title: 'faq',
      title2: 'faq',
      link: '/auth/FAQ',
      icon: <img className={"text-white"} src={logoFAQ} alt="FAQ" />,
    },
    {
      id: 6,
      title: 'contact',
      title2: 'contact',
      link: '/auth/ContactApp',
      icon: <img className={"text-white"} src={logoContact} alt="ContactApp" />,
    },
  ]);

  const [openedNav, setOpenedNav] = useState(true);
  const notifications = useNotifications();
  const { data, request } = useApi(users.me);
  const [userInfo, setUserInfo] = useState<any>(null);

  const [profileFocused, setProfileFocused] = useState(false);
  const [menuFocused, setMenuFocused] = useState(false);

  const [usersData, setUsersData] = useState([]);
  const [SubusersData, setSubUsersData] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [searchStart, setSearchStart] = useState<string>('');
  const { request: requestUser } = useApi(users.getAdherentByLastName);
  const { request: requestUserEmail } = useApi(users.getUserByEmail);

  const {
    data: dataGoals,
    loading: loadingGoals,
    request: requestGoal,
  } = useApi(goals.getByLevel);
  const {
    data: dataFiniGoals,
    loading: loadingFiniGoals,
    request: requestFiniGoal,
  } = useApi(finishedGoals.getByGoalIdAndUserId);
  const {state} = useLocation();
  useEffect(() => {
    request();
  }, []);


  useEffect(() => {
    setUserInfo(data);
    requestGoal(data?.roles ? data.roles[0] : '');
    requestFiniGoal(data?.roles ? data.roles[0] : '', data?.id);
  }, [data]);

  const searchUserEmail = () => {
    requestUser(search).then((res) => {
      const tab: any = [];
      for (let i = 0; i < res.data.length; i++) {
        tab.push({label : res.data[i].lastName + " " + res.data[i].firstName, value : res.data[i].id});
      }
      setSubUsersData(tab);
      setUsersData(tab);
    });
  };


  useEffect(() => {
    if (search?.length > 2) {
      if (search?.length == 3) {
        setSearchStart(search);
        searchUserEmail();
      }
    }
  }, [search]);

  const goToProfil = (value: any) => {
        if(value == null) return;
        navigate(`/auth/adherents/profil/${parseInt(value)}`);
  };

  return isAuth ? (
    <AppShell
      style={{
        position: 'relative',
        transitionDuration: '180ms',
      }}
      // navbarOffsetBreakpoint controls when navbar should no longer be offset with padding-left
      navbarOffsetBreakpoint="sm"
      // fixed prop on AppShell will be automatically added to Header and Navbar
      fixed
      navbar={
        <Navbar
          className={`bg-cover bg-sidebar-desktop ${openedNav ? "bg-right" : null} pb-8 shadow-2xl`}
          padding="md"
          // Breakpoint at which navbar will be hidden if hidden prop is true
          hiddenBreakpoint="sm"
          hidden={window.innerWidth < 770 || openedNav ? true : true}
          // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
          /* ancienne valeur 73*/
          width={{
            base: openedNav ? 220 : 100,
          }}
        >
          <Navbar.Section grow>
            <div className="flex flex-col h-full relative">
              <div
                className={`w-full flex flex-row relative ${
                  openedNav ? 'justify-end' : 'justify-center'
                }`}
                style={{ display: 'flex' }}
                onClick={() => {
                  if (openedNav) {
                    setOpenedNav(false);
                  } else {
                    setOpenedNav(true);
                  }
                }}
              >
                <button
                  id="navbarReducerId"
                  className="flex items-center -right-3 absolute"
                >
                  {openedNav ? (
                    <MdOutlineArrowBackIosNew
                      size={25}
                      className="p-1 text-gray-400"
                    />
                  ) : (
                    <MdOutlineArrowForwardIos
                      size={25}
                      className="p-1 text-gray-400"
                    />
                  )}
                </button>
              </div>
              {openedNav ? (
                <div className="mx-auto pb-24">
                  <Link to="/auth/home">
                    <img className="w-2/3 m-7 transform transition duration-300 hover:scale-[102] " src={logo_fm} alt="" />
                  </Link>
                </div>
              ) : (
                <div className="mx-auto pb-28">
                  <Link to="/auth/home">
                    <img className="w-2/3 m-3" src={picto_FM} alt="" />
                  </Link>
                </div>
              )}
              <div className="mx-2 mt-0 2xl:mt-10 6xl:mt-48">
                {(!data || !data.roles) ? (
                  <div></div>
                ) : (
                data?.roles == 'ROLE_EXPERT_ACCOUNTANT') ? (
                  <Link
                      to={'/auth/expert-comptable'}
                      className={`flex ${
                          openedNav ? 'flex-row' : 'flex-col'
                      }items-center p-1 hover:bg-gray-200 hover:bg-opacity-20 rounded text-white text-s16`}
                  >
                    <div
                        className={` ${
                            openedNav ? 'mr-2' : null
                        }  flex items-center `}
                        style={{ height: '13px', width: '13px' }}
                    >
                      <img className={"text-white"} src={logoAtelier} alt="Tableau de board" />
                    </div>
                    <div className=" uppercase text-thirteen">
                      Tableau de board
                    </div>
                  </Link>
                ) : (
                menuLinks.map((item) => {
                  if (data?.roles == 'ROLE_GUEST') {
                    if (item.id == 0 || item.id == 6) {
                      return (
                          <Link
                              key={item.id}
                              to={item.link}
                              className={`flex ${
                                  openedNav ? 'flex-row' : 'flex-col'
                              }items-center p-1 hover:bg-gray-200 hover:bg-opacity-20 rounded text-white text-s16`}
                          >
                            <div
                                className={` ${
                                    openedNav ? 'mr-2' : null
                                }  flex items-center `}
                                style={{ height: '13px', width: '13px' }}
                            >
                              {item.icon}
                            </div>
                            <div className=" uppercase text-thirteen">
                              {openedNav ?  item.title : item.title2}
                            </div>
                          </Link>
                      );
                    }
                  } else {
                    return (
                        <Link
                            key={item.id}
                            to={item.link}
                            className={`flex ${
                                openedNav ? 'flex-row' : 'flex-col'
                            } items-center p-1 hover:bg-gray-200 hover:bg-opacity-20 rounded text-white text-s16 `}
                        >
                          <div
                              className={` ${
                                  openedNav ? 'mr-2' : null
                              } flex items-center `}
                              style={{ height: '13px', width: '13px' }}
                          >
                            {item.icon}
                          </div>
                          <div className=" uppercase text-thirteen">
                            {openedNav ?  item.title : item.title2}
                          </div>
                        </Link>
                    );
                  }
                })
                )}
              </div>
            </div>
          </Navbar.Section>
          {openedNav ? (
            (!userInfo || !userInfo.roles) ? (
              <div></div>
            ) : (
            userInfo?.roles && userInfo?.roles[0] == "ROLE_EXPERT_ACCOUNTANT") ? (
            <Navbar.Section>
              <Button
                size="xs"
                variant="white"
                compact
                className="my-1 mx-1 border-none text-ten  text-white bg-transparent"
                onClick={() => {
                  logout();
                  navigate('/');
                }}
              >
                Se déconnecter
              </Button>
            </Navbar.Section>
            ) : (
            <Navbar.Section>
              {userInfo?.roles && userInfo?.roles[0] != "ROLE_GUEST" && (
              <div
                className="rounded flex text-white mb-6 mx-1"
                style={{ backgroundColor: '#ffffff33',marginBottom:'2vh',marginTop:`${window.innerHeight > 700 ? "6vh" : "3vh"}` }}
              >
                <BsSearch
                  className="text-white my-auto mx-2"
                />
                <Select
                  variant="unstyled"
                  className="text-ten text-white"
                  styles={{ input: { color: 'white',fontFamily:"aktiv-grotesk" } }}
                  rightSection={<></>}
                  searchable
                  placeholder="Recherchez un adhérent"
                  data={usersData}
                  onSearchChange={(value) => setSearch(value)}
                  onChange={(value: string) => goToProfil(value)}
                  clearable
                ></Select>
              </div>
              )}
              <div className="shadow-md pb-3" style={{background:"linear-gradient(to right, rgba(74,126,168,0.20) 100%, rgba(177,215,230, 0.20) 130%)",marginLeft:"-1rem", paddingLeft: "10px",marginRight:"-1rem",marginBottom:"-2rem"}}>
              <div>
                <div className="float-right pt-3">
                  {userInfo?.isAdmin ? (
                      <Menu
                          className="flex items-center"
                          control={
                            <Burger color="white" size="sm" opened={false} />
                          }
                          position="right"
                          zIndex={1000000000000}
                          transition="rotate-right"
                          transitionDuration={100}
                          transitionTimingFunction="ease"
                      >
                        <Menu.Label>ACTIONS</Menu.Label>
                        <Menu.Item component={Link} to="/auth/workshop/create">
                          Ajouter un atelier
                        </Menu.Item>
                        <Menu.Item component={Link} to="/auth/news/create">
                          Ajouter une actualité
                        </Menu.Item>
                        <Menu.Label>RGPD</Menu.Label>
                        <Menu.Item>
                          <button onClick={() => {
                            notifications.showNotification({
                              title: "Demande des données",
                              message: "Merci pour votre demande, vous recevrez vos données prochainement par mail",
                            })
                          }}>Demander ses données</button>
                        </Menu.Item>

                        <Menu.Label>
                          {userInfo.isAdmin ? "ADMIN" : "EXPERT-COMPTABLE"}
                        </Menu.Label>
                        {userInfo.isAdmin ? (
                          <Menu.Item component={Link} to={"/auth/admin"}>
                            Administration
                          </Menu.Item>
                        ) : (
                          <Menu.Item component={Link} to={"/auth/expert-comptable"}>
                            Module de comptabilité
                          </Menu.Item>
                        )}

                        <Menu.Item>
                          <button onClick={() => {
                            logout();
                            navigate('/');
                          }}>Se déconnecter</button>
                        </Menu.Item>
                      </Menu>
                  ) : data?.roles == 'ROLE_XMAN' || data?.isPilot ? (
                      <Menu
                          className="flex items-center"
                          control={
                            <Burger color="white" size="sm" opened={false} />
                          }
                          position="right"
                          zIndex={1000000000000}
                          transition="rotate-right"
                          transitionDuration={100}
                          transitionTimingFunction="ease"
                      >
                        <Menu.Label>ACTIONS</Menu.Label>
                        <Menu.Item component={Link} to="/auth/workshop/create">
                          Ajouter un atelier
                        </Menu.Item>
                        <Menu.Item component={Link} to="/auth/news/create">
                          Ajouter une actualité
                        </Menu.Item>
                        <Menu.Label>RGPD</Menu.Label>
                        <Menu.Item>
                          <button onClick={() => {
                            notifications.showNotification({
                              title: "Demande des données",
                              message: "Merci pour votre demande, vous recevrez vos données prochainement par mail",
                            })
                          }}>Demander ses données</button>

                        </Menu.Item>
                        <Menu.Item>
                          <button onClick={() => {
                            logout();
                            navigate('/');
                          }}>Se déconnecter</button>
                        </Menu.Item>
                      </Menu>
                  ) : data?.roles == 'ROLE_GUEST' ? (
                      <Menu
                          className="ml-auto"
                          control={
                            <Burger color="white" size="sm" opened={false} />
                          }
                          position="right"
                          zIndex={1000000000000}
                          transition="rotate-right"
                          transitionDuration={100}
                          transitionTimingFunction="ease"
                      >
                        <Menu.Label>RGPD</Menu.Label>
                        <Menu.Item>
                          <button onClick={() => {
                            notifications.showNotification({
                              title: "Demande des données",
                              message: "Merci pour votre demande, vous recevrez vos données prochainement par mail",
                            })
                          }}>Demander ses données</button>
                        </Menu.Item>
                        <Menu.Item>
                          <button onClick={() => {
                            logout();
                            navigate('/');
                          }}>Se déconnecter</button>
                        </Menu.Item>
                      </Menu>
                  ) : (
                      <Menu
                          className="ml-auto"
                          control={
                            <Burger color="white" size="sm" opened={false} />
                          }
                          position="right"
                          zIndex={1000000000000}
                          transition="rotate-right"
                          transitionDuration={100}
                          transitionTimingFunction="ease"
                      >
                        <Menu.Label>RGPD</Menu.Label>
                        <Menu.Item>
                          <button onClick={() => {
                            notifications.showNotification({
                              title: "Demande des données",
                              message: "Merci pour votre demande, vous recevrez vos données prochainement par mail",
                            })
                          }}>Demander ses données</button>
                        </Menu.Item>
                        <Menu.Label>EVOLUTION</Menu.Label>
                        <Menu.Item
                            component={Link}
                            to={`/auth/TrainingPlanUser/${userInfo?.id}`}
                        >
                          Plan de formation
                        </Menu.Item>
                        <Menu.Item>
                          <button onClick={() => {
                            logout();
                            navigate('/');
                          }}>Se déconnecter</button>
                        </Menu.Item>
                      </Menu>
                  )}
                </div>
                  <div onClick={() => navigate(`/auth/adherents/profil/${userInfo?.id}`)} className="m-2 flex cursor-pointer text-white justify-between hover:bg-gray-200 mx-2 hover:bg-opacity-20 rounded">
                    <div className="ml-5 pt-3">
                      <div>
                        <p className="text-ten text-white">Bienvenue</p>
                        <p className="text-ten font-bold">
                          <span className="text-ten font-bold text-white">
                            {userInfo && userInfo?.firstName}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex justify ml-auto gap-x-2 pt-3">
                      <Link to={`/auth/adherents/profil/${userInfo?.id}`}>
                      <img
                        src={
                          userInfo?.fileUrl
                            ? URL2 + userInfo?.fileUrl
                            : profilePicture
                        }
                        className={`rounded hover:scale-125 w-10 ${
                          userInfo?.fileUrl ? 'h-12' : 'h-10'
                        }`}
                        alt=""
                      />
                      </Link>
                    </div>
                  </div>

                  <Divider className="mx-3" />
                </div>
                {userInfo?.roles && userInfo?.roles[0] != "ROLE_GUEST" && (
                <Link to={`/auth/adherents/profil/goals/${userInfo?.id}`} >
                <div className="p-1 py-2 text-white hover:bg-gray-200 hover:bg-opacity-20 mx-2 rounded text-white text-ten  group ">
                  <p className="text-ten float-left">Objectifs validés</p>
                  <p className="text-right font-bold text-ten">
                    {dataFiniGoals.length}/{dataGoals.length}
                  </p>
                </div>
                </Link>
                )}
                  <Divider className="mx-3" />
                  <div onClick={() => navigate(`/auth/adherents/profil/${userInfo?.id}`)} className="p-1 cursor-pointer text-white hover:bg-gray-200 text-ten mx-2 hover:bg-opacity-20 rounded">
                    Mon Organisation
                  </div>
                  <div onClick={() => navigate("/auth/adherents/profil/agenda")} className="p-1 text-white cursor-pointer hover:bg-gray-200 text-ten mx-2 hover:bg-opacity-20 rounded">
                    Mon agenda pédagogique
                  </div>
                  <div onClick={() => navigate("/auth/adherents/profil/Factures")} className="p-1 text-white cursor-pointer hover:bg-gray-200 text-ten mx-2 hover:bg-opacity-20 rounded">
                    Mes factures
                  </div>
                  <div onClick={() => navigate("/auth/adherents/profil/rapports")} className="p-1 text-white hover:bg-gray-200 cursor-pointer text-ten mx-2 hover:bg-opacity-20 rounded">
                    Mes rapports
                  </div>
                  <div className="mt-1">
                    <Divider className="mx-3" />
                  </div>

                <Button
                  size="xs"
                  variant="white"
                  compact
                  className="my-1 mx-1 border-none text-ten  text-white bg-transparent"
                  onClick={() => {
                    logout();
                    navigate('/');
                  }}
                >
                  Se déconnecter
                </Button>
              </div>
              
            </Navbar.Section>
            )
          ) : (
            <Navbar.Section>
              {userInfo?.roles && userInfo?.roles[0] != "ROLE_EXPERT_ACCOUNTANT" && (
              <Link to={`/auth/adherents/profil/${userInfo?.id}`}>
                <img
                  src={
                    userInfo?.fileUrl
                      ? URL2 + userInfo?.fileUrl
                      : profilePicture
                  }
                  className={`rounded shadow-lg shadow-black  w-14 mx-auto ${
                    userInfo?.fileUrl ? 'h-17' : 'h-14'
                  }`}
                  alt=""
                />
                <p className="text-white text-sm text-center mb-10 mt-1">PROFIL</p>
              </Link>
              )}
              <MdLogout
                className="text-white transform transition duration-300 hover:scale-110 shadow-lg shadow-black mx-auto"
                size={25}
                onClick={() => {
                  logout();
                  navigate('/');
                }}
              />
            </Navbar.Section>
          )}
        </Navbar>
      }
    >
      <div className="bg-bg-connect bg-cover bg-fixed h-full -m-4">
        <div style={{
          padding: 'auto'
        }}>
        <MantineProvider theme={{ datesLocale: 'FR' }}>
          <NotificationCard />
            <Outlet />
        </MantineProvider>
        </div>
      </div>

      <div
        className="fixed md:hidden flex flex-row justify-between bottom-0 left-0 bg-white p-4 z-30 "
        style={{ width: '100vw', boxShadow: '0px 1px 6px #8E8E8E59' }}
      >
        <div
          onClick={() => setProfileFocused(!profileFocused)}
          className="flex flex-col items-center text-grayText"
        >
          <div>
            <Anchor
              color="dark"
              component={Link}
              to={`/auth/adherents/profil/${userInfo?.id}`}
            >
              <FaUserAlt className="mx-auto" size={26} />

              <div className="text-eleven pt-2">Mon profil</div>
            </Anchor>
          </div>
        </div>

        <div onClick={()=> window.scrollTo(0, 0)} className="flex flex-col cursor-pointer items-center text-grayText">
          <div>
            <FaBell size={26} className="text-fushiaTonique" />
          </div>
          <div className="text-eleven pt-2">Notifications</div>
        </div>
        <img
          src={connect}
          className="fixed block lg:hidden bottom-0 border-0 right-0 transform z-50 w-32 h-32"
          style={{ zIndex: '-1' }}
          alt=""
        />
        <div
          onClick={() => setMenuFocused(!menuFocused)}
          className="flex flex-col items-center text-grayText"
        >
          <div className="flex flex-col items-center">
            <Menu
              className="ml-auto"
              control={<Burger color="white" size="md" opened={false} />}
              position="top"
              zIndex={1000000000000}
              transition="rotate-right"
              transitionDuration={100}
              transitionTimingFunction="ease"
            >
              <Menu.Label>ACTIONS</Menu.Label>
              <Menu.Item component={Link} to="/auth/TeamMeeting">
                Voir les réunions d'équipes
              </Menu.Item>
              <Menu.Item component={Link} to="/auth/formation">
                Voir les formations
              </Menu.Item>
              <Menu.Item component={Link} to="/auth/workshop/create">
                Ajouter un atelier
              </Menu.Item>
              <Menu.Item component={Link} to="/auth/workshop/create">
                Ajouter un atelier
              </Menu.Item>
              <Menu.Item component={Link} to="/auth/news/create">
                Ajouter une Actualité
              </Menu.Item>
              <Menu.Item component={Link} to="/auth/FAQ/create">
                Ajouter une FAQ
              </Menu.Item>
              <Menu.Label>EVOLUTION</Menu.Label>
              <Menu.Item component={Link} to={`/auth/TrainingPlanUser/${userInfo?.id}`}>
                Plan de formation
              </Menu.Item>
              <Menu.Label>RGPD</Menu.Label>
              <Menu.Item>
                <button onClick={() => {
                  notifications.showNotification({
                    title: "Demande des données",
                    message: "Merci pour votre demande, vous recevrez vos données prochainement par mail",
                  })
                }}>Demander ses données</button>
              </Menu.Item>
              <Menu.Item>
                <button onClick={() => {
                  logout();
                  navigate('/');
                }}>Se déconnecter</button>
              </Menu.Item>
            </Menu>

            <div className="text-eleven text-white pt-1">Menu</div>
          </div>
        </div>
      </div>
    </AppShell>
  ) : (
    <Navigate to="/" />
  );
};

export default HomeConnected;
